// export const API_URL = "https://dev.iquoto.com:5001";
export const API_URL = "https://secure.iquoto.global:8080";
export const SUMSUB_API_URL = "https://api.sumsub.com";

export const encryptionKey = "SigurnoNeZnas!";

export const CLIENT_ADMIN_URL = "https://secure.iquoto.global";
export const WEBSITE_URL = "https://iquoto.global";

export const HUBSPOT_LIFECYCLES = {
  short: {
    42: "Lead",
    61: "Level 1",
    78: "Level 2",
    46: "Registered",
    44: "Deposited",
  },
  full: {
    42: "1. Leads/Welcome",
    61: "2. Level 1 Incomplete Registration",
    78: "3. Level 2 Incomplete Registration",
    46: "4. Successful Registration But No Deposit",
    44: "5. Deposited Client",
  },
};
